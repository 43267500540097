<template>
  <div class="home pd60" v-infinite-scroll="handleInfiniteOnLoad">
    <div class="message">
      <div
        class="ant-list-empty-text flex-center1 mt30"
        style="text-align: center"
        v-if="data.length == 0"
      >
        <div class="ant-empty ant-empty-normal">
          <div class="ant-empty-image">
            <svg
              width="64"
              height="41"
              viewBox="0 0 64 41"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g transform="translate(0 1)" fill="none" fillRule="evenodd">
                <ellipse
                  fill="#F5F5F5"
                  cx="32"
                  cy="33"
                  rx="32"
                  ry="7"
                ></ellipse>
                <g fillRule="nonzero" stroke="#D9D9D9">
                  <path
                    d="M55 12.76L44.854 1.258C44.367.474 43.656 0 42.907 0H21.093c-.749 0-1.46.474-1.947 1.257L9 12.761V22h46v-9.24z"
                  ></path>
                  <path
                    d="M41.613 15.931c0-1.605.994-2.93 2.227-2.931H55v18.137C55 33.26 53.68 35 52.05 35h-40.1C10.32 35 9 33.259 9 31.137V13h11.16c1.233 0 2.227 1.323 2.227 2.928v.022c0 1.605 1.005 2.901 2.237 2.901h14.752c1.232 0 2.237-1.308 2.237-2.913v-.007z"
                    fill="#FAFAFA"
                  ></path>
                </g>
              </g>
            </svg>
          </div>
          <p class="ant-empty-description" style="color: rgba(0, 0, 0, 0.25)">
            暂无数据
          </p>
        </div>
      </div>
      <div
        class="content mt15"
        v-for="item in data"
        :key="item.id"
        @click="goDetail(item.orderId)"
      >
        <span class="time ft-grey">{{ formatDate(item.sendTime) }}</span>
        <div class="content-block bg-block pb15">
          <div class="order-info">
            <h5 class="fz14">{{ item.actionNameAlias }}提醒</h5>
            <p class="fz12 mt10 ft-light">
              {{ item.operateUserName }}
              {{ item.actionName.replace(/给/, "了") }}
              <span class="ft-org"
                >{{ item.orderClassficationName }} |
                {{ item.matterClassficationName }}</span
              >
              事项:<span class="ft-org"> {{ item.title }}</span>
            </p>
            <div class="flex-between border-top mt15 flex-center pt10">
              <span class="ft-light fz12">事项编号：{{ item.orderId }}</span>
              <a-icon type="right" style="color: #bdbdbd" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>
<script>
import infiniteScroll from "vue-infinite-scroll";
import Footer from "@/components/footer/index";
import { Icon } from "ant-design-vue";
import { getMessges } from "@/utils/api";
export default {
  data() {
    return {
      newsList: [
        {
          id: 1,
          time: "2021-10-22 01:01:01",
          username: "张梅梅",
          orderNo: "023366966",
          content:
            "OE047位在预带增压器回油管螺栓时，有时螺栓会掉落在地面上，由于该工位空间狭窄，所以每次掉落...",
        },
        {
          id: 2,
          time: "2021-10-21 23:01:01",
          username: "李梅",
          orderNo: "023366966",
          content:
            "OE047位在预带增压器回油管螺栓时，有时螺栓会掉落在地面上，由于该工位空间狭窄，所以每次掉落...",
        },
      ],
      data: [],
      subList: [],
      subCurrentPage: 1,
    };
  },
  directives: { infiniteScroll },
  components: {
    Footer,
    // ARow: row,
    // ACol: col,
    // // AButton: Button,
    // AAvatar: Avatar,
    // ABadge: Badge,
    AIcon: Icon,
  },
  methods: {
    sendTo(path, index) {
      this.$router.push({ path, query: { index } });
    },
    async getMessges(num) {
      let params = {
        page: this.subCurrentPage + num,
        limit: 5,
      };
      const { data, currentPage, totalPage } = await getMessges(params);
      this.subCurrentPage = currentPage;
      this.subTotalPage = totalPage;
      for (let i = 0; i < data.length; i++) {
        this.subList.push(data[i]);
      }
      this.data = this.subList;
      this.totalPage = this.subTotalPage;
      this.loading = false;
      this.busy = false;
    },
    handleInfiniteOnLoad() {
      if (this.busy) return;
      this.loading = true;
      this.busy = true;
      if (this.data.length === 0) {
        this.getMessges(0);
      } else if (this.currentPage < this.totalPage) {
        this.getMessges(1);
      }
    },
    goDetail(orderId) {
      this.$router.push(`/detail?id=${orderId}`);
    },
  },
  computed: {
    defaultImg() {
      return `this.src="${require("@/assets/img/default.png")}"`;
    },
  },
  created() {
    // this.getMessges();
  },
};
</script>
<style lang="less" scoped>
.message {
  .time {
    display: block;
    text-align: center;
  }
  .content-block {
    padding: 1.4rem;
    margin-top: 1.5rem;
  }
}
</style>